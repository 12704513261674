import "./Conteudo.css";
import Sobre from "./sobre/Sobre";
import Box from "./box/Box";
import Clientes from "./clientes/Clientes";
import Depoimentos from "./depoimentos/Depoimentos";

const Boxes = [
        {
            titulo: 'Missão',
            icone: 'missao',
            texto: 'Gerar valor para a sociedade, por meio da criação de produtos e prestação de serviços, contribuindo para o bem-estar social, formando cidadãos para a construção de um mundo melhor.'
        },{
            titulo: 'Visão',
            icone: 'visao',
            texto: 'Ser uma empresa de referência no mercado de soluções de softwares até 2030, tendo como foco a consolidação como força nacional de mercado, tecnologia e gameficação.'
        },{
            titulo: 'Valores',
            icone: 'valores',
            texto: '1. Ousadia e inovação são a nossa marca; 2. Nossa gente é o nosso maior patrimônio; 3. Existimos para servir nossos clientes; 4. Buscamos a excelência empresarial; 5. Construímos um futuro sustentável.'
        }
    ];

function Conteudo() {
    return (
        <main className="container-fluid conteudo">
            <Sobre></Sobre>
            <section className="missao-valores-visao container-fluid">
                <div className="row">
                    {
                        Boxes.map((item, index) => (
                            <Box key={index} titulo={item.titulo} icone={item.icone} texto={item.texto}></Box>
                        ))
                    }
                </div>
            </section>
            <Clientes></Clientes>
            <Depoimentos></Depoimentos>
        </main>
    );
}

export default Conteudo