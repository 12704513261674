import Footer from "../footer/Footer";
import Header from "../header/Header";
import "./politica.css";

let img_tablet = require("../../_assets/img/outras imagens/tablet.png");
let pdf = require("../../documents/privacidade.pdf");

function PoliticaDePrivacidade() {
  return (
    <>
      <Header></Header>
      <main className="conteudo">
        <div className="titulo-principal">
          <h3>POLÍTICA DE PRIVACIDADE</h3>
        </div>
        <div className="linha-titulo">
          <hr />
        </div>

        <section className="conteudo-projetos">
          <div className="cards-projetos">
            <div className="pdf">
              <div className="texto">
                POLÍTICA DE PRIVACIDADE
                <br />
                <br />
                1 Informações gerais
                <br />
                <br />
                A presente Política de Privacidade contém informações a respeito
                do modo como tratamos, total ou parcialmente, de forma
                automatizada ou não, os dados pessoais dos usuários que acessam
                nosso aplicativo. Seu objetivo é esclarecer os interessados
                acerca dos tipos de dados que são coletados, dos motivos da
                coleta e da forma como o usuário poderá atualizar, gerenciar ou
                excluir estas informações.
                <br />
                <br />
                Esta Política de Privacidade foi elaborada em conformidade com a
                Lei Federal n. 12.965 de 23 de abril de 2014 (Marco Civil da
                Internet), com a Lei Federal n. 13.709, de 14 de agosto de 2018
                (Lei de Proteção de Dados Pessoais) e com o Regulamento UE n.
                2016/679 de 27 de abril de 2016 (Regulamento Geral Europeu de
                Proteção de Dados Pessoais - RGDP).
                <br />
                <br />
                Esta Política de Privacidade poderá ser atualizada em
                decorrência de eventual atualização normativa, razão pela qual
                se convida o usuário a consultar periodicamente esta seção.
                <br />
                <br />
                2 Direitos do usuário
                <br />
                <br />
                O aplicativo se compromete a cumprir as normas previstas no
                RGPD, em respeito aos seguintes princípios:
                <br />
                <br />
                - Os dados pessoais do usuário serão processados de forma
                lícita, leal e transparente (licitude, lealdade e
                transparência);
                <br />
                <br />
                - Os dados pessoais do usuário serão coletados apenas para
                finalidades determinadas, explícitas e legítimas, não podendo
                ser tratados posteriormente de uma forma incompatível com essas
                finalidades (limitação das finalidades);
                <br />
                <br />
                - Os dados pessoais do usuário serão coletados de forma
                adequada, pertinente e limitada às necessidades do objetivo para
                os quais eles são processados (minimização dos dados);
                <br />
                <br />
                - Os dados pessoais do usuário serão exatos e atualizados sempre
                que necessário, de maneira que os dados inexatos sejam apagados
                ou retificados quando possível (exatidão);
                <br />
                <br />
                - Os dados pessoais do usuário serão conservados de uma forma
                que permita a identificação dos titulares dos dados apenas
                durante o período necessário para as finalidades para as quais
                são tratados (limitação da conservação); - Os dados pessoais do
                usuário serão tratados de forma segura, protegidos do tratamento
                não autorizado ou ilícito e contra a sua perda, destruição ou
                danificação acidental, adotando as medidas técnicas ou
                organizativas adequadas (integridade e confidencialidade).
                <br />
                <br />
                <br />
                <br />
                O usuário do aplicativo possui os seguintes direitos, conferidos
                pela Lei de Proteção de Dados Pessoais e pelo RGPD:
                <br />
                <br />
                - Direito de confirmação e acesso: é o direito do usuário de
                obter do aplicativo a confirmação de que os dados pessoais que
                lhe digam respeito são ou não objeto de tratamento e, se for
                esse o caso, o direito de acessar os seus dados pessoais;
                <br />
                <br />
                - Direito de retificação: é o direito do usuário de obter do
                aplicativo, sem demora injustificada, a retificação dos dados
                pessoais inexatos que lhe digam respeito; - Direito à eliminação
                dos dados (direito ao esquecimento): é o direito do usuário de
                ter seus dados apagados do aplicativo;
                <br />
                <br />
                - Direito à limitação do tratamento dos dados: é o direito do
                usuário de limitar o tratamento de seus dados pessoais, podendo
                obtê-la quando contesta a exatidão dos dados, quando o
                tratamento for ilícito, quando o aplicativo não precisar mais
                dos dados para as finalidades propostas e quando tiver se oposto
                ao tratamento dos dados e em caso de tratamento de dados
                desnecessários;
                <br />
                <br />
                - Direito de oposição: é o direito do usuário de, a qualquer
                momento, se opor por motivos relacionados com a sua situação
                particular, ao tratamento dos dados pessoais que lhe digam
                respeito, podendo se opor ainda ao uso de seus dados pessoais
                para definição de perfil de marketing (profiling);
                <br />
                <br />
                - Direito de portabilidade dos dados: é o direito do usuário de
                receber os dados pessoais que lhe digam respeito e que tenha
                fornecido ao aplicativo, num formato estruturado, de uso
                corrente e de leitura automática, e o direito de transmitir
                esses dados a outro aplicativo;
                <br />
                <br />
                - Direito de não ser submetido a decisões automatizadas: é o
                direito do usuário de não ficar sujeito a nenhuma decisão tomada
                exclusivamente com base no tratamento automatizado, incluindo a
                definição de perfis (profiling), que produza efeitos na sua
                esfera jurídica ou que o afete significativamente de forma
                similar.
                <br />
                <br />
                O usuário poderá exercer os seus direitos por meio de
                comunicação escrita enviada ao aplicativo com o assunto "RGDP-",
                especificando:
                <br />
                <br />
                - Nome completo ou razão social, número do CPF (Cadastro de
                Pessoas Físicas, da Receita Federal do Brasil) ou CNPJ (Cadastro
                Nacional de Pessoa Jurídica, da Receita Federal do Brasil) e
                endereço de e-mail do usuário e, se for o caso, do seu
                representante;
                <br />
                <br />
                - Direito que deseja exercer junto ao aplicativo;
                <br />
                <br />
                - Data do pedido e assinatura do usuário;
                <br />
                <br />
                - Todo documento que possa demonstrar ou justificar o exercício
                de seu direito.
                <br />
                <br />
                O pedido deverá ser enviado ao e-mail: dsgamessuporte@gmail.com,
                ou por correio, ao seguinte endereço:
                <br />
                <br />
                DS GAMES E SISTEMAS LTDA
                <br />
                <br />
                AV LEITAO DA SILVA 180 EDIFÍCIO ATLANTIS TOWER SALA 501
                29.052-110
                <br />
                <br />
                PRAIA DO SUA VITORIA ES
                <br />
                <br />
                O usuário será informado em caso de retificação ou eliminação
                dos seus dados.
                <br />
                <br />
                3 Dever de não fornecer dados de terceiros Durante a utilização
                do site, a fim de resguardar e de proteger os direitos de
                terceiros, o usuário do aplicativo deverá fornecer somente seus
                dados pessoais, e não os de terceiros.
                <br />
                <br />
                4 Informações coletadas
                <br />
                <br />
                A coleta de dados dos usuários se dará em conformidade com o
                disposto nesta Política de Privacidade e dependerá do
                consentimento do usuário, sendo este dispensável somente nas
                hipóteses previstas no art. 11, inciso II, da Lei de Proteção de
                Dados Pessoais.
                <br />
                <br />
                4.1 Tipos de dados coletados
                <br />
                <br />
                4.1.1 Dados sensíveis
                <br />
                <br />
                Não serão coletados dados sensíveis dos usuários, assim
                entendidos aqueles definidos nos arts. 9º e 10 do RGPD e nos
                arts. 11 e seguintes da Lei de Proteção de Dados Pessoais.
                Assim, dentre outros, não haverá coleta dos seguintes dados: -
                dados que revelem a origem racial ou étnica, as opiniões
                políticas, as convicções religiosas ou filosóficas, ou a
                filiação sindical do usuário;
                <br />
                <br />
                - dados genéticos;
                <br />
                <br />
                - dados biométricos para identificar uma pessoa de forma
                inequívoca;
                <br />
                <br />
                - dados relativos à saúde do usuário;
                <br />
                <br />
                - dados relativos à vida sexual ou à orientação sexual do
                usuário;
                <br />
                <br />
                - dados relacionados a condenações penais ou a infrações ou com
                medidas de segurança conexas.
                <br />
                <br />
                4.1.2 Coleta de dados não previstos expressamente
                <br />
                <br />
                Eventualmente, outros tipos de dados não previstos expressamente
                nesta Política de Privacidade poderão ser coletados, desde que
                sejam fornecidos com o consentimento do usuário, ou, ainda, que
                a coleta seja permitida ou imposta por lei.
                <br />
                <br />
                4.2 Fundamento jurídico para o tratamento dos dados pessoais
                <br />
                <br />
                Ao utilizar os serviços do aplicativo, o usuário está
                consentindo com a presente Política de Privacidade.
                <br />
                <br />
                O usuário tem o direito de retirar seu consentimento a qualquer
                momento, não comprometendo a licitude do tratamento de seus
                dados pessoais antes da retirada.
                <br />
                <br />
                A retirada do consentimento poderá ser feita pelo e-mail:
                dsgamessuporte@gmail.com, ou por correio enviado ao seguinte
                endereço: AV LEITAO DA SILVA 180 EDIFÍCIO ATLANTIS TOWER SALA
                501 29.052-110 PRAIA DO SUA VITORIA ES
                <br />
                <br />
                O consentimento dos relativamente ou absolutamente incapazes,
                especialmente de crianças menores de 16 (dezesseis) anos, apenas
                poderá ser feito, respectivamente, se devidamente assistidos ou
                representados.
                <br />
                <br />
                O tratamento de dados pessoais sem o consentimento do usuário
                apenas será realizado em razão de interesse legítimo ou para as
                hipóteses previstas em lei, ou seja, dentre outras, as
                seguintes:
                <br />
                <br />
                - para o cumprimento de obrigação legal ou regulatória pelo
                controlador;
                <br />
                <br />
                - para a realização de estudos por órgão de pesquisa, garantida,
                sempre que possível, a anonimização dos dados pessoais;
                <br />
                <br />
                - quando necessário para a execução de contrato ou de
                procedimentos preliminares relacionados a contrato do qual seja
                parte o usuário, a pedido do titular dos dados;
                <br />
                <br />
                - para o exercício regular de direitos em processo judicial,
                administrativo ou arbitral, esse último nos termos da Lei nº
                9.307, de 23 de setembro de 1996 (Lei de Arbitragem);
                <br />
                <br />
                - para a proteção da vida ou da incolumidade física do titular
                dos dados ou de terceiro;
                <br />
                <br />
                - para a tutela da saúde, em procedimento realizado por
                profissionais da área da saúde ou por entidades sanitárias;
                <br />
                <br />
                - quando necessário para atender aos interesses legítimos do
                controlador ou de terceiro, exceto no caso de prevalecerem
                direitos e liberdades fundamentais do titular dos dados que
                exijam a proteção dos dados pessoais;
                <br />
                <br />
                - para a proteção do crédito, inclusive quanto ao disposto na
                legislação pertinente.
                <br />
                <br />
                4.3 Finalidades do tratamento dos dados pessoais
                <br />
                <br />
                Os dados pessoais do usuário coletados pelo aplicativo têm por
                finalidade facilitar, agilizar e cumprir os compromissos
                estabelecidos com o usuário e a fazer cumprir as solicitações
                realizadas por meio do preenchimento de formulários.
                <br />
                <br />
                Os dados pessoais poderão ser utilizados também com uma
                finalidade comercial, para personalizar o conteúdo oferecido ao
                usuário, bem como para dar subsídio ao aplicativo para a melhora
                da qualidade e funcionamento de seus serviços.
                <br />
                <br />
                O tratamento de dados pessoais para finalidades não previstas
                nesta Política de Privacidade somente ocorrerá mediante
                comunicação prévia ao usuário, sendo que, em qualquer caso, os
                direitos e obrigações aqui previstos permanecerão aplicáveis.
                <br />
                <br />
                4.4 Prazo de conservação dos dados pessoais
                <br />
                <br />
                Os dados pessoais do usuário serão conservados pelo prazo máximo
                de: 12 meses, exceto se o usuário solicitar a sua supressão
                antes do final deste prazo.
                <br />
                <br />
                Os dados pessoais dos usuários apenas poderão ser conservados
                após o término de seu tratamento nas seguintes hipóteses:
                <br />
                <br />
                - para o cumprimento de obrigação legal ou regulatória pelo
                controlador;
                <br />
                <br />
                - para estudo por órgão de pesquisa, garantida, sempre que
                possível, a anonimização dos dados pessoais;
                <br />
                <br />
                - para a transferência a terceiro, desde que respeitados os
                requisitos de tratamento de dados dispostos na legislação;
                <br />
                <br />
                - para uso exclusivo do controlador, vedado seu acesso por
                terceiro, e desde que anonimizados os dados.
                <br />
                <br />
                4.5 Destinatários e transferência dos dados pessoais
                <br />
                <br />
                Os dados pessoais do usuário não serão compartilhadas com
                terceiros. Serão, portanto, tratados apenas por este aplicativo.
                <br />
                <br />
                5 Do tratamento dos dados pessoais
                <br />
                <br />
                5.1 Do responsável pelo tratamento dos dados (data controller)
                <br />
                <br />
                O controlador, responsável pelo tratamento dos dados pessoais do
                usuário, é a pessoa física ou jurídica, a autoridade pública, a
                agência ou outro organismo que, individualmente ou em conjunto
                com outras, determina as finalidades e os meios de tratamento de
                dados pessoais.
                <br />
                <br />
                Neste aplicativo, o responsável pelo tratamento dos dados
                pessoais coletados é DS GAMES E SISTEMAS LTDA, representada por
                DOUGLAS SERRANO
                <br />
                <br />
                NASCIMENTO, que poderão ser contactados pelo e-mail:
                dsgamessuporte@gmail.com ou no endereço: AV LEITAO DA SILVA 180
                EDIFÍCIO ATLANTIS TOWER SALA 501 29.052-110 PRAIA DO SUA VITORIA
                ES
                <br />
                <br />
                O aplicativo possui também os seguintes responsáveis pelo
                tratamento dos dados pessoais coletados: Henrique Machado
                Broseghini Rua Interventor Santos Neves, casa número 01, Centro,
                Fundão , Espírito Santo broseghinibros@gmail.com O responsável
                pelo tratamento dos dados se encarregará diretamente do
                tratamento dos dados pessoais do usuário.
                <br />
                <br />
                5.2 Do encarregado de proteção de dados (data protection
                officer) O encarregado de proteção de dados (data protection
                officer) é o profissional encarregado de informar, aconselhar e
                controlar o responsável pelo tratamento dos dados, bem como os
                trabalhadores que tratem os dados, a respeito das obrigações do
                aplicativo nos termos do RGDP, da Lei de Proteção de Dados
                Pessoais e de outras disposições de proteção de dados presentes
                na legislação nacional e internacional, em cooperação com a
                autoridade de controle competente.
                <br />
                <br />
                Neste aplicativo o encarregado de proteção de dados (data
                protection officer) é HENRIQUE MACHADO BROSEGHINI, que poderá
                ser contactado pelo e-mail: broseghinibros@gmail.com. 6
                Segurança no tratamento dos dados pessoais do usuário
                <br />
                <br />
                O aplicativo se compromete a aplicar as medidas técnicas e
                organizativas aptas a proteger os dados pessoais de acessos não
                autorizados e de situações de destruição, perda, alteração,
                comunicação ou difusão de tais dados.
                <br />
                <br />
                Para a garantia da segurança, serão adotadas soluções que levem
                em consideração: as técnicas adequadas; os custos de aplicação;
                a natureza, o âmbito, o contexto e as finalidades do tratamento;
                e os riscos para os direitos e liberdades do usuário.
                <br />
                <br />
                No entanto, o aplicativo se exime de responsabilidade por culpa
                exclusiva de terceiro, como em caso de ataque de hackers ou
                crackers, ou culpa exclusiva do usuário, como no caso em que ele
                mesmo transfere seus dados a terceiro. O aplicativo se
                compromete, ainda, a comunicar o usuário em prazo adequado caso
                ocorra algum tipo de violação da segurança de seus dados
                pessoais que possa lhe causar um alto risco para seus direitos e
                liberdades pessoais. A violação de dados pessoais é uma violação
                de segurança que provoque, de modo acidental ou ilícito, a
                destruição, a perda, a alteração, a divulgação ou o acesso não
                autorizados a dados pessoais transmitidos, conservados ou
                sujeitos a qualquer outro tipo de tratamento. Por fim, o
                aplicativo se compromete a tratar os dados pessoais do usuário
                com confidencialidade, dentro dos limites legais.
                <br />
                <br />
                7 Reclamação a uma autoridade de controle
                <br />
                <br />
                Sem prejuízo de qualquer outra via de recurso administrativo ou
                judicial, todos os titulares de dados têm direito a apresentar
                reclamação a uma autoridade de controle. A reclamação poderá ser
                feita à autoridade da sede do aplicativo, do país de residência
                habitual do usuário, do seu local de trabalho ou do local onde
                foi alegadamente praticada a infração.
                <br />
                <br />
                8 Das alterações A presente versão desta Política de Privacidade
                foi atualizada pela última vez em: 03/11/2020.
                <br />
                <br />
                O editor se reserva o direito de modificar, a qualquer momento o
                aplicativo as presentes normas, especialmente para adaptá-las às
                evoluções do aplicativo SPACE BALL, seja pela disponibilização
                de novas funcionalidades, seja pela supressão ou modificação
                daquelas já existentes.
                <br />
                <br />
                O usuário será explicitamente notificado em caso de alteração
                desta política. Ao utilizar o serviço após eventuais
                modificações, o usuário demonstra sua concordância com as novas
                normas. Caso discorde de alguma das modificações, deverá
                interromper, imediatamente, o acesso ao aplicativo e apresentar
                a sua ressalva ao serviço de atendimento, se assim o desejar.
                <br />
                <br />
                9 Do Direito aplicável e do foro Para a solução das
                controvérsias decorrentes do presente instrumento, será aplicado
                integralmente o Direito brasileiro. Os eventuais litígios
                deverão ser apresentados no foro da comarca em que se encontra a
                sede do editor do aplicativo.
                <br />
                <br />
              </div>
            </div>
            <img className="tablet" src={img_tablet} alt=""></img>
          </div>
          <div className="btn-download">
            <button className="btn btn-success">
              <a className="download" href={pdf} download="politica_de_privacidade">
                DOWNLOAD
              </a>
            </button>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </>
  );
}

export default PoliticaDePrivacidade;
