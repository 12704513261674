import "./Box.css"

function Box(props){
    return (
        <div className="box">
            <div className={props.icone}/>
            <p className="titulo text-uppercase">{props.titulo}</p>
            <p className="texto">{props.texto}</p>
        </div>
    );
}

export default Box;