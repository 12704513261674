import "./Carrossel.css";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
const img1 =  require('../../_assets/img/carrossel/1 dev sites.png');
const img2 =  require('../../_assets/img/carrossel/2 dev jogos.png');
const img3 =  require('../../_assets/img/carrossel/3 consultoria.png');
const imgVerMais =  require('../../_assets/img/icones/botão ver mais.png');

function Carrossel() {
    return <div id="carrossel-ds" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
            <button type="button" data-bs-target="#carrossel-ds" data-bs-slide-to="0" className="active rounded-circle" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carrossel-ds" data-bs-slide-to="1" className=" rounded-circle" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carrossel-ds" data-bs-slide-to="2" className=" rounded-circle" aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner">
            <div className="carousel-item active">
                <Link to="/projetos#des-sites" className="link-ver-mais">
                    <img src={img1} className="d-block w-100" alt="Desenvolvimento de sites"/>
                    <div className="carousel-caption d-md-block">
                        <h5 className="titulo-branco titulo-carrossel text-uppercase">Desenvolvimento de sites</h5>
                        <img src={imgVerMais} className="img-ver-mais" alt="Ver Mais"/>
                    </div>
                </Link>
            </div>
            <div className="carousel-item">
                <HashLink to="/projetos#des-jogos" className="link-ver-mais">
                    <img src={img2} className="d-block w-100" alt="..."/>
                    <div className="carousel-caption d-md-block">
                        <h5 className="titulo-branco titulo-carrossel text-uppercase">Desenvolvimento de jogos</h5>
                        <img src={imgVerMais} className="img-ver-mais" alt="Ver Mais"/>
                    </div>
                </HashLink>
            </div>
            <div className="carousel-item">
                <HashLink to="/projetos#consultoria" className="link-ver-mais">
                    <img src={img3} className="d-block w-100" alt="..."/>
                    <div className="carousel-caption d-md-block">
                        <h5 className="titulo-branco titulo-carrossel text-uppercase">Consultoria</h5>
                        <img src={imgVerMais} className="img-ver-mais" alt="Ver Mais"/>
                    </div>
                </HashLink>
            </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carrossel-ds" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carrossel-ds" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
    </div>;
}

export default Carrossel;