import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Footer.css";

function Footer() {
  const date = new Date();
  const currentYear = date.getFullYear();
  return (
    <footer>
      <div className="footer-copyright">
        <h3>
          DS Games & Sistemas | Copyright © { currentYear } – Todos os direitos reservados.
        </h3>
      </div>
      <div className="icons">
        <div className="twitter">
          <a
            href="https://twitter.com/DsGamesOfi"
            className="icones"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              icon={faTwitter}
              className="fa-solid fa-Twitter fa-2xl fa-inverse fa-fw"
            />
          </a>
        </div>
        <div className="instagram">
          <a
            href="https://www.instagram.com/dsgamesof/"
            className="icones"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              icon={faInstagram}
              className="fa-solid fa-Instagram fa-2xl fa-inverse fa-fw"
            />
          </a>
        </div>
        <div className="facebook">
          <a
            href="https://www.facebook.com/DsGamesOf/"
            className="icones"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              icon={faFacebook}
              className="fa-solid fa-Facebook fa-2xl fa-inverse fa-fw"
            />
          </a>
        </div>
        <div className="linkedin">
          <a
            href="https://www.linkedin.com/company/ds-games-sistemas/"
            className="icones"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              icon={faLinkedin}
              className="fa-solid fa-Linkedin fa-2xl fa-inverse fa-fw"
            />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
