import "./Contato.css";
/* import Form from "../form/Form"; */
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Contato() {
  return (
    <section className="contato" id="contato">
      <div className="cards">
        <div className="fale-conosco">
          <h1>FALE CONOSCO</h1>

          <div className="contatoDiv">
            <a href="https://wa.me/5527992026776" className="contatoLink contato-whatsapp" target="_blank" rel="noreferrer">
              <FontAwesomeIcon
                icon={faWhatsapp}
                className="fa-solid fa-Whatsapp fa-2xl fa-inverse fa-fw icone"
              />
              <span className="h3">Tel: + 55 27 99202-6776</span>
            </a>
            <a href="mailto:faleconosco@dsgamesesistemas.com.br" className="contatoLink contato-email">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="fa-solid fa-envelope fa-2xl fa-inverse fa-fw icone"
              />
              <span className="h3">faleconosco@dsgamesesistemas.com.br</span>
            </a>
            <a href="#contato" className="contatoLink contato-endereco">
              <FontAwesomeIcon
                icon={faLocationDot}
                className="fa-solid fa-location fa-2xl1 fa-inverse fa-fg icone"
              />
              <span className="h3">
                Av.Leitão da Silva, 180, Praia do<br /> Suá - Ed.Alantis Tower
                Sala 501
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contato;
