import "./Depoimentos.css";

function Depoimentos() {
    return (
        <section className="depoimentos container">
            <div className="row titulo-secao">
                <span className="titulo-verde text-uppercase">Depoimentos</span>
            </div>
            <div className="row linha-borda">
                <div className="linha-verde"></div>
            </div>
            <div className="row">
                <div id="carrosselDepoimentos" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carrosselDepoimentos" data-bs-slide-to="0" className="active rounded-circle" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carrosselDepoimentos" data-bs-slide-to="1" className="rounded-circle" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carrosselDepoimentos" data-bs-slide-to="2" className="rounded-circle" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carrosselDepoimentos" data-bs-slide-to="3" className="rounded-circle" aria-label="Slide 4"></button>
                    </div>
                    <div className="carousel-inner" role="listbox">
                        <div className="carousel-item active text-center p-4">
                            <p className="texto">“Empresa com um excelente atendimento e desenvolvimento de softwares de ponta, com qualidade,  preço justo e prazo de entrega.”</p>
                            <p className="autor-nome">Ruan Queiroz de Paula Afonso</p>
                            <p className="funcao-autor">Clínica Movement</p>
                        </div>
                        <div className="carousel-item text-center p-4">
                            <p className="texto">“Bom serviço prestado, não tive dor de cabeça. Recomendo.”</p>
                            <p className="autor-nome">André Oliveira</p>
                            <p className="funcao-autor">Engenheiro</p>
                        </div>
                        <div className="carousel-item text-center p-4">
                            <p className="texto">“Gratidão por um site de belíssima qualidade e responsivo! Ele irá nos ajudar bastante na divulgação!”</p>
                            <p className="autor-nome">Sebastião Vieira de Almeida</p>
                            <p className="funcao-autor">Pastor</p>
                        </div>
                        <div className="carousel-item text-center p-4">
                            <p className="texto">“Empresa com ótimo atendimento e esclarecimentos, com compromissos e resultados dentro do prazo.”</p>
                            <p className="autor-nome">Rogério Scharaiber</p>
                            <p className="funcao-autor">Engenheiro</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Depoimentos;