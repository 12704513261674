import Header from "../header/Header";
import Carrossel from "../carrossel/Carrossel";
import Conteudo from "../conteudo/Conteudo";
import Contato from "../contato/Contato";
import Footer from "../footer/Footer";

function Home() {
  return (
    <>
      <Header></Header>
      <Carrossel></Carrossel>
      <Conteudo></Conteudo>
      <Contato></Contato>
      <Footer></Footer>
    </>
  );
}

export default Home;
