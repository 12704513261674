import "./Clientes.css";

function Clientes() {
    return (
        <section className="clientes container-fluid" id="clientes">
            <div className="row titulo-secao">
                <span className="titulo-verde text-uppercase">Os Nossos clientes</span>
            </div>
            <div className="row linha-borda">
                <div className="linha-verde"></div>
            </div>
            <div className="row linha-clientes">
                <ul className="lista-clientes">
                    <li className="cliente">
                        <a href="#clientes" className="link-img-cliente pibv">
                        </a>
                    </li>
                    <li className="cliente">
                        <a href="#clientes" className="link-img-cliente mmartins">
                        </a>
                    </li>
                    <li className="cliente">
                        <a href="#clientes" className="link-img-cliente support">
                        </a>
                    </li>
                    <li className="cliente">
                        <a href="#clientes" className="link-img-cliente adns">
                        </a>
                    </li>
                    <li className="cliente">
                        <a href="#clientes" className="link-img-cliente pibbds">
                        </a>
                    </li>
                    <li className="cliente">
                        <a href="#clientes" className="link-img-cliente movement">
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    );
}

export default Clientes;