import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import "./Header.css";
const logo = require("../../_assets/img/LOGOTIPO REGISTRADA/Logo versao 3.png");

function Header() {
  return (
    <header className="cabecalho">
      <div className="container-fluid items">
        <nav className="links navbar navbar-expand-md navbar-light">
          <div className="menu">
            <h1 className="logo navbar-brand">
              <Link to="/" className="link h3">
                <img src={logo} alt="" className="logo-img"></img>
              </Link>
            </h1>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#myNavbar"
              aria-controls="myNavbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="myNavbar">
              <ul className="links-list navbar-nav">
                <li className="col nav-item">
                    <HashLink to="/#sobre" className="link h3">
                        Sobre
                    </HashLink>
                </li>
                <li className="col nav-item">
                    <Link to="/projetos" className="link h3">
                        Projetos
                    </Link>
                </li>
                <li className="col nav-item">
                    <HashLink to="/#clientes" className="link h3">
                        Clientes
                    </HashLink>
                </li>
                <li className="col nav-item">
                    <HashLink to="/#contato" className="link h3">
                        Contato
                    </HashLink>
                </li>
                <li className="col nav-item">
                  <Link to="/politicadeprivacidade" className="link h3">
                    Política de
                    <br />
                    Privacidade
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
