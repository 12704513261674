import "./Sobre.css";
import imgSobre from "../../../_assets/img/outras imagens/sobre img.png";

function Sobre() {
    return (
        <section className="sobre container-fluid" id="sobre">
            <div className="row">
                <p className="titulo-secao h2 text-uppercase text-center col-md-12">
                    <span className="titulo-normal">Sobre a</span>
                    <span className="titulo-verde"> DS Games & Sistemas</span>
                </p>
            </div>
            <div className="row linha-texto">
                <div className="texto">
                    <p className="">
                        Somos uma empresa de tecnologia 100% capixaba, com sede em Vitória- ES, com ênfase em desenvolvimento de soluções voltadas para construção de sites, aplicações WEB, desenvolvimento de sistemas customizados e desenvolvimento de Games usando as plataformas Unity e Unreal Engine, umas das mais renomadas do mercado de Games.
                    </p>
                    <p className="">
                        Contamos com profissionais qualificados para tais soluções, para que sua empresa, seja de pequeno ou grande porte, saia na frente com o uso de tecnologias de ponta, afinal tecnologia sempre foi nossa marca!
                    </p>
                </div>
                <div className="imagem-sobre">
                    <img src={imgSobre} alt="" className="img-sobre"/>
                </div>
            </div>
        </section>
    );
}

export default Sobre;