import "bootstrap/dist/css/bootstrap.css";
import Home from "./components/routes/home";
import Projetos from "./components/routes/projeto";
import PoliticaDePrivacidade from "./components/routes/politica"
import "./fonte.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/projetos" element={<Projetos />}></Route>
          <Route path="/politicadeprivacidade" element={<PoliticaDePrivacidade/>}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
