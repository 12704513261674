import Footer from "../footer/Footer";
import Header from "../header/Header";
import "./projeto.css";

const img1 = require("../../_assets/img/projetos/dev.png");
const img2 = require("../../_assets/img/projetos/dev-mobile.jpg");
const img3 = require("../../_assets/img/projetos/cons.png");
const img4 = require("../../_assets/img/projetos/Planetário 1.png");
const img5 = require("../../_assets/img/projetos/Tabuleiro das finanças II 1.png");
const img6 = require("../../_assets/img/projetos/Space Ball 1.png");
const img7 = require("../../_assets/img/projetos/botoes animados.gif");

function Projetos() {
  return (
    <>
      <Header></Header>
      <main className="conteudo">
        <div className="titulo-principal">
          <h3>PROJETOS & SERVIÇOS</h3>
        </div>
        <div className="linha-titulo">
          <hr />
        </div>

        <section className="conteudo-projetos">
          <div className="cards-projetos">
            <div className="desenvolvimento" id="des-sites">
              <h3>
                DESENVOLVIMENTO
                <br /> DE SITES
              </h3>
              <img src={img1} alt="Desenvolvimento de Sites"></img>
              <p>
                Pensando em dar um upgrade no seu negócio? Pode contar com a
                equipe qualificada da DS Games para atender seus objetivos!
              </p>
            </div>
            <div className="desenvolvimento">
              <h3>
                DESENVOLVIMENTO DE
                <br />
                APLICAÇÕES MOBILE
              </h3>
              <img src={img2} alt="Desenvolvimento de aplicações Mobile"></img>
              <p>
                Desenvolvimento de Aplicativos Mobile para diferentes tipos de aplicações, seja Android, IOS e similares, usando tecnologias apropriadas para o seu negócio!
              </p>
            </div>
            <div className="desenvolvimento" id="consultoria">
              <h3>CONSULTORIA<br/>PARA SUA EMPRESA</h3>
              <img src={img3} alt="Consultoria para a sua empresa"></img>
              <p>
                Suas aplicações ou sistemas estão com problemas? Temos a
                solução sobre medida para você! Conte com a equipe qualificada
                da DS GAMES & SISTEMAS com atendimento presencial ou
                remotamente!
              </p>
            </div>
            <div className="desenvolvimento" id="des-jogos">
              <h3>
                PLANETÁRIO
                <br />
                INTERATIVO
              </h3>
              <img src={img4} alt="Planetário Interativo"></img>
              <p>
                Software educacional voltado para o aprendizado de crianças e
                adolescentes sobre o sistema solar, de forma dinâmica e
                interativa, mostrando as informações de cada planeta, assim como
                demonstra a velocidade de cada um em tempo mais realista
                possível. Disponível para Windows, Android e MacBook.
              </p>
            </div>
            <div className="desenvolvimento">
              <h3>
                TABULEIRO DAS <br />
                FINANÇAS
              </h3>
              <img src={img5} alt="Tabuleiro das Finanças"></img>
              <p>
                Software educacional que leva os alunos a repensarem de forma
                didática e intuitiva sobre o uso consciente do dinheiro e
                empreendedorismo, desde de aquisição de empréstimos para seu
                negócio, assim como lidar com as adversidades empresariais.
                Material completo. Disponível na forma WEB, sem necessidade de
                instalar programas extras.
              </p>
            </div>
            <div className="desenvolvimento">
              <h3>GAME <br/>SPACE BALL</h3>
              <img src={img6} alt="Game Space Ball"></img>
              <p>
                Embarque nessa aventura sideral com Trucker e desbrave os
                mistérios galácticos, sem cair e ser sugado pelos buracos negros
                misteriosos! Disponível na Play Store.
              </p>
            </div>
            <div className="desenvolvimento">
              <h3>BOTÕES<br/>ANIMADOS</h3>
              <img src={img7} alt="Botões Animados"></img>
              <p>
                Já pensou em ter algum botão animado para seu projeto ou alguma
                arte seja institucional ou para gameificação? A equipe da DS
                GAMES & Sistemas possui a melhor equipe de designer do Espírito
                Santo, disposta em te auxiliar em todas as suas demandas.
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </>
  );
}

export default Projetos;
